// TO-DO move this to API
import { z } from "zod";

// Enum Schemas
const BillingTypeSchema = z.union([z.literal("CreditCard"), z.literal("ACH"), z.literal("STRIPE")]);

const BillingFrequencySchema = z.union([z.literal("Monthly"), z.literal("Annually")]);

const CreditCardTypeSchema = z.union([
    z.literal("Unknown"),
    z.literal("MasterCard"),
    z.literal("Visa"),
    z.literal("Discover"),
    z.literal("AmericanExpress"),
    z.literal("UnionPay"),
    z.literal("JCB"),
    z.literal("DinersClub")
]);

type CreditCardType = z.infer<typeof CreditCardTypeSchema>;

// CreditCardExpirationDate Schema
const CreditCardExpirationDateSchema = z.object({
    month: z.union([
        z.literal("None"),
        z.literal("January"),
        z.literal("February"),
        z.literal("March"),
        z.literal("April"),
        z.literal("May"),
        z.literal("June"),
        z.literal("July"),
        z.literal("August"),
        z.literal("September"),
        z.literal("October"),
        z.literal("November"),
        z.literal("December")
    ]),
    year: z.number()
});

type CreditCardExpirationDate = z.infer<typeof CreditCardExpirationDateSchema>;

// BillingInformation Schema
export class PTZBillingUtils {
    static mapCreditCardBrand = (brand: string): CreditCardType => {
        const brandMapping: Record<string, CreditCardType> = {
            mastercard: "MasterCard",
            visa: "Visa",
            discover: "Discover",
            amex: "AmericanExpress",
            unionpay: "UnionPay",
            jcb: "JCB",
            diners: "DinersClub"
        };

        return brandMapping[brand.toLowerCase()] || "Unknown";
    };

    static convertStripeExpDetails = ({ month, year }: { month: number; year: number }): CreditCardExpirationDate => {
        const monthNames: Record<number, CreditCardExpirationDate["month"]> = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December"
        };

        return {
            month: monthNames[month] ?? "None",
            year: year ?? 0
        };
    };
}

export const PTZBillingInformationSchema = z.object({
    billingType: BillingTypeSchema.or(z.literal(0)),
    billingFrequency: BillingFrequencySchema,
    nameOnAccount: z.string().optional(),
    expirationDate: CreditCardExpirationDateSchema.optional(),
    routingNumber: z.string().optional(),
    accountNumber: z.string().optional(),
    accountNumberLast4: z.string().optional(),
    eligibilityId: z.string().optional(),
    company: z.string().optional(),
    stripeConfirmationToken: z.string().optional(),
    creditCardType: CreditCardTypeSchema.optional()
});

export type PTZBillingInformation = z.infer<typeof PTZBillingInformationSchema>;
