import { PublicConfig } from "../PublicConfig";
import { NotificationFrequency } from "../types/Notifications";

export class NotificationUtils {
    static isIAICState(state: string | undefined): boolean {
        if (!state) return false;
        return PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_IAIC.includes(state);
    }

    static isUSFState(state: string | undefined): boolean {
        if (!state) return false;
        return PublicConfig.PTZ_US.VALID_GIFTCARD_STATES_USF.includes(state);
    }

    static isAmazonEligible(state: string | undefined): boolean {
        if (!state) return false;
        return this.isIAICState(state) || this.isUSFState(state);
    }

    static shouldShowNotification(lastClosed: string | undefined, frequency: NotificationFrequency | undefined): boolean {
        if (!lastClosed) {
            // Show notification if there is no last closed time, regardless of the frequency status.
            return true;
        }

        if (!frequency) {
            // Do not show the notification if there is a last closed time but no frequency set.
            return false;
        }

        // Calculate the next visibility time and compare it to the current time
        const lastClosedDate = new Date(lastClosed);
        const now = new Date();
        const minutesToAdd = this.getMinutesToAdd(frequency);
        lastClosedDate.setMinutes(lastClosedDate.getMinutes() + minutesToAdd);

        return now >= lastClosedDate;
    }

    private static getMinutesToAdd(frequency: NotificationFrequency): number {
        switch (frequency) {
            case "hourly":
                return 60;
            case "daily":
                return 1440; // 60 * 24
            case "weekly":
                return 10080; // 60 * 24 * 7
            case "monthly":
                return 43800; // Approx 60 * 24 * 30.5
            case "yearly":
                return 525600; // Approx 60 * 24 * 365
            default:
                return 0;
        }
    }
}
