import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

interface UseFormRedirectProps {
    formId: string;
    isEnabled: boolean;
}

export const useFormRedirect = ({ formId, isEnabled }: UseFormRedirectProps) => {
    const query = useQuery({
        queryKey: ["formRedirect", formId],
        queryFn: async () => {
            return { success: true };
        },
        enabled: isEnabled && !!formId,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });

    useEffect(() => {
        if (query.isSuccess) {
            // Create URL object from current location
            const currentUrl = new URL(window.location.href);

            // Set the form-id parameter while preserving other query params
            currentUrl.searchParams.set("form-id", formId);

            // Update the location preserving all existing params
            window.location.href = currentUrl.toString();
        }
    }, [query.isSuccess, formId]);

    return query;
};
