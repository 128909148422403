import React from "react";

//Data
import { SectionData } from "./coverageData";

//Components
import SectionSubHeading from "./SectionSubHeading";
import TableRow from "./TableRow";

interface IllnessSubSectionProps {
    subSection: SectionData;
}

const IllnessSubSection: React.FC<IllnessSubSectionProps> = ({ subSection }) => {
    return (
        <div className="flex w-full flex-col gap-3 md:w-1/2">
            <SectionSubHeading icon={subSection.icon} title={subSection.title} />
            <div>
                {subSection.includes.map(inclusion => {
                    return <TableRow key={inclusion.title} title={inclusion.title} description={inclusion.description} />;
                })}
            </div>
        </div>
    );
};

export default IllnessSubSection;
