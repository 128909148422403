import { z } from "zod";
import { PetSchema, OwnerSchema, MarketingSchema, ExtraQuoteSchema, UnknownExtraSchema } from "@/shared/types/Quote.interface";
import { RegexUtils } from "@/shared/utils/RegexUtils";
import Strings from "@/shared/utils/Strings.constants";

// Step Schema
export const UsPolicyStepSchema = OwnerSchema.extend({
    policies: z.array(PetSchema),
    marketing: MarketingSchema,
    phone: z
        .string()
        .refine(value => value === "" || RegexUtils.phoneRegex.test(value), Strings.ERRORS.PHONE)
        .optional(),
    extra: ExtraQuoteSchema.and(UnknownExtraSchema).optional()
});

export const UsPetPoliciesSchema = z.object({
    policies: z.array(PetSchema)
});
export const PartialUsPetPoliciesSchema = UsPetPoliciesSchema.deepPartial();

export const PartialUsPolicyStepSchema = UsPolicyStepSchema.extend({ policies: z.array(PetSchema) }).deepPartial();

export type PartialUsPetPolicies = z.infer<typeof PartialUsPetPoliciesSchema>;
export type UsPetPolicies = z.infer<typeof UsPetPoliciesSchema>;
export type PartialUsPolicyStepType = z.infer<typeof PartialUsPolicyStepSchema>;
export type UsPolicyStepType = z.infer<typeof UsPolicyStepSchema>;
