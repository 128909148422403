// packages
import * as z from "zod";
import { useFormContext, Controller } from "react-hook-form";

// components
import { Button, Checkbox, Label } from "@/shared/components/ui";
import { FormField } from "@/shared/components/FormField";
import { FormHint } from "@/shared/components/FormHint";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";

//hooks
import { useModal } from "@/shared/hooks/useModal";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// types
import { BillingStepSchema, PartialQuoteSchema } from "@/shared/types/Quote.interface";
import { FunctionComponent, useCallback } from "react";
import { ContactConsentModal } from "./ContactConsentModal";
import { Quote } from "@/shared/types/Quote.interface";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";

// utils
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { PublicConfig } from "@/shared/PublicConfig";
import { cn } from "@/shared/utils";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { UIUtils } from "@/shared/utils/UIUtils";
import { UsQuoteFormSteps } from "@/quote-ptz-us/formConfigTypes";
import Strings from "@/shared/utils/Strings.constants";

type AllBillingStepProps = z.infer<typeof BillingStepSchema>;

interface TermsProps {
    handleExtraChange: (value: any) => void;
    quoteExtra: Quote["extra"];
    isQuoteUpdating?: boolean;
    wrapperClass?: string;
    priorityCode?: string;
}

const ContactConsentDisclaimer = (props: TermsProps) => {
    const { handleExtraChange, quoteExtra, isQuoteUpdating, wrapperClass } = props;
    const modal = useModal();
    const isReferAFriendEnabled = PublicConfig.IS_REFER_A_FRIEND_ENABLED;

    const formID = useAppLayerContext().appState.formID;
    const underwriter = useAppLayerContext().appState.underwriter;

    const builderUtils = new BuilderUtils(underwriter as PetUnderwriterType);

    const onTermsChange = (value: boolean) => {
        if (errors.extra?.termsConsent) {
            clearErrors("extra.termsConsent");
        }
        setValue("extra.termsConsent", value, { shouldTouch: true, shouldDirty: true });

        const rewards = quoteExtra?.rewards;
        let hasSubmittedRewardsApp = false;

        // The rewards.applicationSubmitted value is either a string (V2) or an object. Check for both.
        // If the user has already submitted the rewards application, don't send another applicationSubmitted event.
        if (!!rewards) {
            if (typeof rewards === "object" && !!rewards?.applicationSubmitted) {
                hasSubmittedRewardsApp = true;
            }
            if (typeof rewards === "string" && rewards.includes("applicationSubmitted")) {
                hasSubmittedRewardsApp = true;
            }
        }

        // If the Refer A Friend program is disabled OR user has not consented to the terms OR the application has already been submitted, don't update the rewards object:
        if (!isReferAFriendEnabled || !value || hasSubmittedRewardsApp || isQuoteUpdating) return;

        handleExtraChange({ rewards: { applicationSubmitted: new Date() } });
    };

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    const {
        control,
        setValue,
        clearErrors,
        formState: { errors }
    } = useFormContext<AllBillingStepProps>();

    const { setValue: setParentValue, getValues: getParentValues } = useFormParentContext();
    const quote = getParentValues();
    const consentError = errors?.extra?.termsConsent?.message;
    const consentErrorClasses = {
        "border border-stroke-danger rounded-md p-4 shadow-md": consentError
    };
    const { dirtyValues } = useDirtyValues<Quote>();
    const builderData = UIUtils.deepMerge(quote, dirtyValues, PartialQuoteSchema).mergedBase as Quote;

    const iaicVerbiageV3 = PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V3;
    const quoteState = quote?.ratingAddress?.state;
    const isApplicantState = iaicVerbiageV3.includes(quoteState as string);

    return (
        <div className={cn("mx-auto flex max-w-xl flex-col gap-6", wrapperClass)}>
            <Controller
                name="extra.termsConsent"
                control={control}
                render={({ field: { ref, value } }) => (
                    <div className={cn("flex flex-col transition-all", consentErrorClasses)}>
                        <FormField className="flex-row gap-3">
                            <Checkbox
                                id="terms-consent"
                                aria-labelledby="terms-consent-label"
                                checked={value}
                                ref={ref}
                                error={consentError}
                                disabled={isQuoteUpdating}
                                onCheckedChange={(value: boolean) => onTermsChange(value)}
                            />
                            <Label id="terms-consent-label" htmlFor="terms-consent" className="flex flex-col bg-background-transparent text-xs text-content-secondary">
                                <p className="text-sm text-content-primary">
                                    I have read, accept the{" "}
                                    <Button
                                        onClick={() => handleOpenModal(ContactConsentModal)}
                                        className="text-sm text-content-primary underline hover:bg-background-transparent"
                                        variant="ghost"
                                        size="auto"
                                    >
                                        policy terms and fraud notice
                                    </Button>
                                </p>
                                {isApplicantState && (
                                    <>
                                        <p>By clicking the box, you are submitting your application.</p>
                                    </>
                                )}

                                <CustomizationSlot
                                    type="terms-disclaimer"
                                    data={builderData}
                                    formId={formID}
                                    formStepId={UsQuoteFormSteps.BILLING.id}
                                    modalData={quote?.extra?.modalStates}
                                    formData={quote?.extra?.formData}
                                    updateData={data => builderUtils.updateQuoteExtraData({ quote, newDataArray: data, updateQuote: setParentValue })}
                                    priorityCode={props.priorityCode}
                                />
                            </Label>
                        </FormField>
                        {errors?.extra?.termsConsent && (
                            <FormHint variant="error" className="ml-6">
                                {errors?.extra.termsConsent?.message}
                            </FormHint>
                        )}
                    </div>
                )}
            />
            <Controller
                name="paperless"
                control={control}
                render={({ field: { ref, value } }) => (
                    <>
                        <FormField className="flex-row gap-2">
                            <Checkbox
                                id="paperless-consent"
                                aria-labelledby="paperless-consent-label"
                                checked={value}
                                ref={ref}
                                onCheckedChange={(value: boolean) => setValue("paperless", value, { shouldTouch: true, shouldDirty: true })}
                            />
                            <Label
                                id="paperless-consent-label"
                                htmlFor="paperless-consent"
                                className="flex max-w-[375px] flex-col bg-background-transparent text-xs text-content-secondary"
                            >
                                <span className="text-sm text-content-primary">Go paperless</span>
                                {value ? (
                                    <span>Get your policy and communications online.</span>
                                ) : (
                                    <span>
                                        Your policy documents and other communications will be mailed to your billing address. If you want these mailed to a different address,
                                        please call <a href={`tel:${Strings.PTZ_US.PHONE_NUMBER}`}>{UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER)}</a> to complete your
                                        enrollment.
                                    </span>
                                )}
                            </Label>
                        </FormField>
                    </>
                )}
            />
            {modal.render}
        </div>
    );
};

export default ContactConsentDisclaimer;
