import React from "react";

//Icons
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TableRowProps {
    title: string;
    description?: string;
}

const TableRow: React.FC<TableRowProps> = ({ title, description }) => {
    return (
        <div className="flex gap-2 rounded px-2 py-1 text-content-primary odd:bg-background-secondary">
            <div className="flex size-6 items-center justify-center">
                <FontAwesomeIcon icon={faCircleCheck} />
            </div>
            <div className="flex flex-col">
                <h5 className="text-sm font-semibold">{title}</h5>
                {description && <p className="text-xs font-medium">{description}</p>}
            </div>
        </div>
    );
};

export default TableRow;
