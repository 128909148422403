"use client";

import React, { useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PublicConfig } from "@/shared/PublicConfig";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import StripeUtils from "@/quote-ptz-us/src/components/StripeCheckout/StripeUtils";

// Call outside component render to avoid recreating Stripe object on every render
const stripePromise = loadStripe(PublicConfig.STRIPE_PUBLISHABLE_KEY);

interface StripeProviderProps {
    children: React.ReactNode;
    amount: number;
}

export function StripeProvider({ children, amount }: StripeProviderProps) {
    const elementsOptions = StripeUtils.getElementsOptions(amount);

    return (
        <Elements stripe={stripePromise} options={elementsOptions}>
            <StripeContextUpdater />
            {children}
        </Elements>
    );
}

function StripeContextUpdater() {
    const { updateAppState } = useAppLayerContext();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        updateAppState({ stripe: stripe, stripeElements: elements });
    }, [updateAppState, stripe, elements]);

    return null;
}
