import React, { ReactNode } from "react";

interface SectionSubHeadingProps {
    icon: ReactNode;
    title: string;
}

const SectionSubHeading: React.FC<SectionSubHeadingProps> = ({ icon, title }) => {
    return (
        <div className="flex items-center gap-3">
            <div className="flex size-12 items-center justify-center">{icon}</div>
            <h4 className="text-sm font-semibold">{title}</h4>
        </div>
    );
};

export default SectionSubHeading;
