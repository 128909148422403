import { PublicConfig } from "@/shared/PublicConfig";
import React from "react";

const GiftCardDisclaimer = ({ alignLeft }: { alignLeft?: boolean }) => {
    return (
        <div className={`${alignLeft ? "" : "mx-auto"} max-w-[976px] text-xs text-content-secondary`}>
            Amazon.com Gift Card* is subject to eligibility, not available in all states.{" "}
            <a href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/amz-terms`} className="underline" target="_blank">
                Eligibility terms
            </a>
            .
        </div>
    );
};

export default GiftCardDisclaimer;
