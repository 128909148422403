import React, { ReactNode } from "react";
import { cn } from "../utils";

interface DetailRowProps {
    label?: string | ReactNode;
    value: string | ReactNode;
    wrapperClass?: string;
    labelClass?: string;
    valueClass?: string;
}

const DetailRow: React.FC<DetailRowProps> = ({ label, value, wrapperClass, labelClass, valueClass }) => {
    return (
        <div className={cn(`flex ${label ? "justify-between" : "justify-end"} text-sm`, wrapperClass)}>
            {label && <span className={cn("text-content-secondary", labelClass)}>{label}</span>}
            <span className={valueClass}>{value}</span>
        </div>
    );
};

export default DetailRow;
