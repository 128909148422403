// React and utilities
import React, { useMemo, useState, useRef } from "react";
import Image from "next/image";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

// Components
import { IconButton, Dialog, DialogContent, DialogClose, Button, Heading, DialogTitle, DialogDescription } from "@/shared/components/ui";
import TableRow from "./TableRow";
import IllnessSubSection from "./IllnessSubsection";
import SectionHeading from "./SectionHeading";

// Icons and images
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faXmark } from "@fortawesome/pro-solid-svg-icons";
import ThreeInOnePetsBanner from "@/shared/media/images/1in3pets.webp";
import EverySixSeconds from "@/shared/media/images/every6seconds.webp";
import IllustratedTelehealth from "@/shared/media/icons/policy/Telehealth.svg";

// Data
import { general, accident, illness, illnessSubSections, coverageExclusions, IncludesItem, SectionData } from "./coverageData";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { Quote } from "@/shared/types/Quote.interface";
import { DialogProps } from "@/shared/types/Modals";

// Utils
import { UIUtils } from "@/shared/utils/UIUtils";
import { SAMPLE_POLICY_URL } from "../../utils/constants";
import Strings from "@/shared/utils/Strings.constants";

type TabOption = "covered" | "notCovered";

interface TabbedDialogProps extends DialogProps {
    initialTab?: TabOption;
}

const FullCoverageDetailsModal: React.FC<TabbedDialogProps> = ({ open, onClose, initialTab = "covered" }) => {
    // form state
    const { watch } = useFormParentContext<Quote>();
    const policies = watch("policies");
    const hasIllnessCoverage = policies?.some(policy => policy.coverageSettings?.coverages?.some(coverage => coverage.type === "illness"));
    const dialogRef = useRef<HTMLDivElement>(null);

    //Tabs state and style
    const [activeTab, setActiveTab] = useState(initialTab);
    const activeTabClass = "border-b-2 border-stroke-brand text-content-primary";
    const inactiveTabClass = "border-b border-stroke-secondary text-content-secondary";
    const tabClass = "w-1/2 rounded-none text-sm font-bold hover:no-underline";
    const isCoveredTab = activeTab === "covered";

    // Combine general and accident includes when there's no illness coverage for any policy
    const combinedIncludes: IncludesItem[] = useMemo(() => {
        return hasIllnessCoverage ? general.includes : [...general.includes, ...accident.includes];
    }, [hasIllnessCoverage]);

    function handleOverlayClick() {
        if (onClose) {
            onClose(false);
        }
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    const handleSetActiveTab = (tab: TabOption) => {
        setActiveTab(tab);
        if (dialogRef.current) {
            UIUtils.scrollToTop(500, 50, dialogRef.current);
        }
    };

    return (
        <div onClick={handleOverlayClick}>
            <Dialog open={open}>
                <DialogContent ref={dialogRef} onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                    <VisuallyHidden asChild>
                        <DialogTitle>{Strings.COVERAGE_DETAILS}</DialogTitle>
                    </VisuallyHidden>
                    <VisuallyHidden asChild>
                        <DialogDescription>{Strings.COVERAGE_DETAILS}</DialogDescription>
                    </VisuallyHidden>
                    <DialogClose asChild>
                        <IconButton onClick={() => onClose && onClose(false)}>
                            <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                        </IconButton>
                    </DialogClose>

                    <div className="flex w-full flex-col gap-6 text-content-primary">
                        {/* Heading and Tabs */}
                        <div className="flex flex-col gap-4">
                            <h3 className="mt-3 text-center text-xl font-semibold md:mt-1 lg:mt-0">{Strings.COVERAGE_DETAILS}</h3>
                            <div className="flex justify-center">
                                <Button className={`${tabClass} ${isCoveredTab ? activeTabClass : inactiveTabClass}`} variant="link" onClick={() => setActiveTab("covered")}>
                                    What&apos;s Covered
                                </Button>
                                <Button className={`${tabClass} ${!isCoveredTab ? activeTabClass : inactiveTabClass}`} variant="link" onClick={() => setActiveTab("notCovered")}>
                                    What&apos;s not Covered
                                </Button>
                            </div>
                        </div>
                        {/* What's covered */}
                        {activeTab === "covered" && (
                            <div className="flex flex-col gap-6">
                                {/* General Section */}
                                <div className="flex flex-col gap-3">
                                    <SectionHeading icon={general.icon} title={hasIllnessCoverage ? general.illnessTitle : general.title} />
                                    <div>
                                        {combinedIncludes.map(inclusion => {
                                            return <TableRow key={inclusion.title} title={inclusion.title} description={inclusion.description} />;
                                        })}
                                    </div>
                                </div>
                                {/* Accident Section */}
                                {hasIllnessCoverage && (
                                    <div className="flex flex-col items-center gap-4 md:flex-row">
                                        {/* Left column */}
                                        <div className="flex w-full flex-col gap-3 md:w-1/2">
                                            <SectionHeading icon={accident.icon} title={accident.title} />
                                            <div>
                                                {accident.includes.map(inclusion => {
                                                    return <TableRow key={inclusion.title} title={inclusion.title} />;
                                                })}
                                            </div>
                                        </div>
                                        {/* Right column */}
                                        <div className="flex w-full items-center justify-center max-md:max-w-[296px] md:w-1/2">
                                            <Image src={ThreeInOnePetsBanner} alt={"3 in 1 pet"} />
                                        </div>
                                    </div>
                                )}
                                {/* Illness Section */}
                                {hasIllnessCoverage && (
                                    <div className="flex flex-col gap-4">
                                        <SectionHeading icon={illness.icon} title={illness.title} />
                                        {/* Row */}
                                        <div className="flex flex-col gap-4 md:flex-row">
                                            {/* Left column */}
                                            <IllnessSubSection subSection={illnessSubSections.breedSpecific as SectionData} />
                                            {/* Right column */}
                                            <IllnessSubSection subSection={illnessSubSections.dentalIllnesses as SectionData} />
                                        </div>
                                        {/* Row */}
                                        <div className="flex flex-col items-center gap-4 md:flex-row">
                                            {/* Left column */}
                                            <IllnessSubSection subSection={illnessSubSections.cancer as SectionData} />
                                            {/* Right column */}
                                            <div className="flex w-full items-center justify-center max-md:max-w-[296px] md:w-1/2">
                                                <Image src={EverySixSeconds} alt={"3 in 1 pet"} />
                                            </div>
                                        </div>
                                        {/* Row */}
                                        <div className="flex flex-col gap-4 md:flex-row">
                                            {/* Left column */}
                                            <IllnessSubSection subSection={illnessSubSections.chronicConditions as SectionData} />
                                            {/* Right column */}
                                            <IllnessSubSection subSection={illnessSubSections.orthopedicConditions as SectionData} />
                                        </div>
                                    </div>
                                )}
                                {/* VetAccess Section */}
                                <div className="flex flex-col justify-center gap-4 md:flex-row">
                                    {!hasIllnessCoverage && (
                                        <div className="flex w-full items-center justify-center max-md:max-w-[296px] md:w-1/2">
                                            <Image src={ThreeInOnePetsBanner} alt={"3 in 1 pet"} />
                                        </div>
                                    )}
                                    <div className={`flex w-full flex-col gap-6 pb-6 ${hasIllnessCoverage ? "" : "md:w-1/2"}`}>
                                        <div className="flex flex-col items-center gap-3">
                                            <SectionHeading icon={<IllustratedTelehealth className="size-16" />} title="24/7 Pet Health Helpline is Included in Spot plans too!" />
                                            <p className="max-w-[423px] text-center text-sm font-semibold text-content-secondary">
                                                Get immediate access to a 24/7 helpline for pet health, behavior, and wellness questions powered by VetAccess™.
                                            </p>
                                        </div>
                                        <Button asChild className="h-5 py-0 text-center text-sm font-semibold underline" variant="link">
                                            <a href={SAMPLE_POLICY_URL} target="_blank">
                                                View a Sample Policy
                                            </a>
                                        </Button>
                                        <Button
                                            className="h-5 py-0 text-center text-sm font-semibold text-content-brand hover:no-underline"
                                            variant="link"
                                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                                            onClick={() => handleSetActiveTab("notCovered")}
                                        >
                                            What&apos;s not covered
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* What's not covered */}
                        {activeTab === "notCovered" && (
                            <div className="flex flex-col items-center gap-6 pb-6 text-center text-sm">
                                <Heading level={"h2"} className="text-base font-semibold">
                                    Like all pet insurance providers, we have exclusions.
                                </Heading>
                                {coverageExclusions.map((exclusion, index) => (
                                    <div key={index}>
                                        <Heading level={"h3"} className="text-sm font-semibold">
                                            {exclusion.title}
                                        </Heading>
                                        <p className="text-content-secondary">{exclusion.description}</p>
                                    </div>
                                ))}
                                <Button
                                    className="h-5 py-0 text-center text-sm font-semibold text-content-brand hover:no-underline"
                                    variant="link"
                                    startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                                    onClick={() => handleSetActiveTab("covered")}
                                >
                                    What&apos;s covered
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FullCoverageDetailsModal;
