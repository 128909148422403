import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/pro-solid-svg-icons";
import { PublicConfig } from "@/shared/PublicConfig";
import { NotificationUtils } from "@/shared/utils/NotificationUtils";
import Strings from "@/shared/utils/Strings.constants";

export const AmazonToast = ({ state }: { state: string | undefined }) => {
    if (!state) return null;

    const getGiftCardText = () => {
        if (NotificationUtils.isIAICState(state)) {
            if (PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V2.includes(state)) {
                return (
                    <>
                        {Strings.PTZ_US.GIFTCARD.SIGN_UP} {Strings.PTZ_US.GIFTCARD.REWARD}!*
                    </>
                );
            }
            return (
                <>
                    {Strings.PTZ_US.GIFTCARD.APPLY} {Strings.PTZ_US.GIFTCARD.REWARD}!*
                </>
            );
        }
        return <>{Strings.PTZ_US.GIFTCARD.APPLIED}</>;
    };

    return (
        <div className="max-w-screen flex items-start gap-1">
            <div className="flex size-5 shrink-0 items-center justify-center">
                <FontAwesomeIcon icon={faGift} className="text-content-success" height="16px" width="16px" />
            </div>
            <span className="text-sm font-semibold leading-5">{getGiftCardText()}</span>
        </div>
    );
};
