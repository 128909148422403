import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import { PublicConfig } from "../PublicConfig";
import { FormStepProps } from "../types/Form";
import { Quote } from "../types/Quote.interface";
import { Button } from "./ui/Button";
import { UIUtils } from "../utils/UIUtils";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { QuoteOutageEvent } from "spot-types/events/OutageEvent";
import DogIllustration from "@/shared/media/images/dog-01.svg";
import { useEffect } from "react";
import Strings from "../utils/Strings.constants";

type BillingOutageProps = FormStepProps<Quote> & { underwriter: PetUnderwriterType };
export const BillingOutage = (props: BillingOutageProps) => {
    const { underwriter } = props;

    const activeOutages = PublicConfig.PTZ_US.ACTIVE_OUTAGES;
    const basePath = PublicConfig.BASE_PATH;
    const quoteId = props.value?.id ?? ``;
    const apiUrl = `${basePath}/api/events`;

    const formattedPhone = UIUtils.formatPhone(Strings.PTZ_US.PHONE_NUMBER, ".");

    const isBillingOutage = activeOutages.includes("ptz-us-billing-api");

    const eventData: QuoteOutageEvent = {
        type: "track/outage/quote",
        data: {
            uw: underwriter,
            quoteID: quoteId,
            email: props.value?.email ?? ``,
            outageID: "ptz-us-billing-api",
            accountID: props.value?.accountID
        }
    };

    useEffect(() => {
        if (!isBillingOutage) {
        }
    }, [isBillingOutage]);

    useQuery({
        queryKey: [`billing-outage`, quoteId],
        queryFn: async () => {
            try {
                await axios.post(apiUrl, eventData);
                return true;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error(`Error posting outage event: `, error.response?.data);
                } else {
                    console.error(`Error posting outage event: `, error);
                }
                return false;
            }
        },
        enabled: isBillingOutage
    });

    const getHeading = () => {
        if (isBillingOutage) {
            return `We are currently unable to process payments.`;
        }

        return Strings.PROBLEM_FETCH_QUOTE;
    };

    const getCopy = () => {
        if (isBillingOutage) {
            return `Don’t worry, it’s on our end not yours. We’ll email you when we’re back online or you can speak with a help center agent to purchase your policy over the phone.`;
        }
        return `Don’t worry, we’ll email you as soon as it’s ready. You can also speak with a help center agent to get your quote now over the phone.`;
    };

    return (
        <div className="flex min-h-[60vh] flex-col justify-center gap-8 py-4 sm:gap-10 md:gap-12 lg:min-h-[33vh] lg:gap-14 xl:gap-16">
            {/* Info Banner */}
            <div className="flex flex-col items-center justify-center gap-8 sm:flex-row sm:gap-4">
                {/* Left Column */}
                <div className="flex max-w-xs flex-col gap-4 md:max-w-[400px]">
                    {/* Copy */}
                    <div className="flex flex-col gap-1 text-center text-content-primary sm:gap-3 sm:text-start">
                        <h2 className="text-lg font-semibold sm:text-3xl">{getHeading()}</h2>
                        <p className="text-xs sm:text-base">{getCopy()}</p>
                    </div>

                    {/* Call Center CTA */}
                    <div className="flex flex-col items-center gap-3 sm:items-start md:flex-row md:items-center md:gap-6">
                        <a href={`tel:${Strings.PTZ_US.PHONE_NUMBER}`}>
                            <Button className="w-fit rounded-full" startDecorator={<FontAwesomeIcon icon={faPhone} />}>
                                {formattedPhone}
                            </Button>
                        </a>
                        <p className="text-xs text-content-primary">{UIUtils.getPhoneHours("PTZ_US")}</p>
                    </div>
                </div>

                {/* Right Column */}
                <div className="flex w-full max-w-xs items-center justify-center md:max-w-[400px]">
                    <DogIllustration />
                </div>
            </div>
        </div>
    );
};
