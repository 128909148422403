"use client";

import { useEffect, useState } from "react";
import Script from "next/script";
import { PublicConfig } from "@/shared/PublicConfig";

export const Partner247Loader = () => {
    const [origin, setOrigin] = useState<string | null>(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setOrigin(window.location.origin);
        }
    }, []);

    const shouldRenderScript = () => {
        if (PublicConfig.DEV_ENV && origin === "https://quote.spotpet.dev") {
            return true;
        }
        if (!PublicConfig.DEV_ENV && origin === "https://quote.spotpet.com") {
            return true;
        }
        return false;
    };

    return shouldRenderScript() ? <Script src={`${PublicConfig.BASE_PATH}/psp247/247tag.${PublicConfig.DEV_ENV ? `STAGE.` : `PROD.`}js`} strategy="afterInteractive" /> : null;
};
