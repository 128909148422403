import { UIUtils } from "@/shared/utils/UIUtils";
import { Heading } from "@/shared/components/ui";
import { cn } from "@/shared/utils";

interface PolicySummaryFooterProps {
    policiesCount: number;
    transactionFee: number | undefined;
    isAnnualBilling: boolean;
    totalPrice: number;
    isSingleCol?: boolean;
    summaryStyleVariant: string;
}

const PolicySummaryFooter = (props: PolicySummaryFooterProps) => {
    const { policiesCount, transactionFee = 0, isAnnualBilling, totalPrice, isSingleCol = false, summaryStyleVariant } = props;

    const formattedTotalPrice = UIUtils.formatNumber(totalPrice ?? 0, 2);

    // Container styles
    const dynamicPaddingClass = summaryStyleVariant === "default" ? "px-4 pb-4" : "p-4";
    const dynamicColumnsClass = !isSingleCol && "sm:grid-cols-2 sm:gap-12";

    // Text styles
    const rowLabelStyles = summaryStyleVariant === "default" ? "text-base" : "text-base sm:text-lg";
    const rowValueStyles = summaryStyleVariant === "default" ? "text-base" : "text-lg sm:text-xl";

    return (
        <div className={cn("grid text-content-primary", dynamicPaddingClass, dynamicColumnsClass)}>
            <div></div>
            <div className="flex flex-col gap-2">
                <div className={cn("flex flex-row justify-between text-xs", isAnnualBilling && "line-through")}>
                    <p>Monthly Transaction Fee</p>
                    <p>
                        <b>{UIUtils.formatCurrency(transactionFee)}</b>
                    </p>
                </div>
                <div className="flex flex-row justify-between">
                    <Heading level="h4" className={cn("font-bold", rowLabelStyles)}>
                        Grand Total
                    </Heading>
                    <div className={cn(rowValueStyles, "font-semibold")}>
                        <span data-testid="policy-summary-total-price" data-value={formattedTotalPrice}>
                            {`(${policiesCount} ${policiesCount === 1 ? "Pet" : "Pets"}) $${formattedTotalPrice}`}
                        </span>
                        /{isAnnualBilling ? "yr" : "mo"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicySummaryFooter;
