import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";
import { useEffect } from "react";

export const StatsigTopPayment = () => {
    const { updateAppState } = useAppLayerContext();
    // #statsig:start - policy-step-no-pet-checkbox
    const { billingShowTopExpressPayment } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "billingShowTopExpressPayment", defaultValue: false }] as const
    });
    // #statsig:end

    useEffect(() => {
        updateAppState({ billingShowTopExpressPayment });
    }, [billingShowTopExpressPayment, updateAppState]);

    return null;
};
