import React, { ReactNode } from "react";

interface SectionHeadingProps {
    icon: ReactNode;
    title: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ icon, title }) => {
    return (
        <div className="flex flex-col items-center gap-3">
            <div className="flex size-16 items-center justify-center">{icon}</div>
            <h4 className="text-center text-base font-semibold">{title}</h4>
        </div>
    );
};

export default SectionHeading;
